import {api} from "Api"
import {getField, updateField} from "vuex-map-fields"
import _ from 'lodash'

const getDefaultState = () => {
    return {
        allActiveSuspendedSuppliers: [],
        allSuppliers: [],
        filteredActiveSuspendedSuppliers: [],
        filteredSuppliers: [],
        AgentCommissions: [],
        Company: {
            id: null,
            address1: null,
            address2: null,
            address3: null,
            brnno: null,
            city: null,
            country_id: null,
            contacts: [],
            email: null,
            fax: null,
            GPCustomerNumber: null,
            gstno: null,
            latitude: null,
            longitude: null,
            name: null,
            override_ots_country: null,
            postcode: null,
            sstno: null,
            state: null,
            taxno_type: null,
            tel: null,
            uscc: null,
            website: null
        },
        Supplier: {
            id: null,
            account_name: null,
            auto_notifications: 1,
            code: null,
            company_id: null,
            customcommfunc: null,
            fsc: null,
            documents: [],
            fsc_certificate: null,
            fsc_expiry_date: null,
            fsc_material_category: null,
            fsc_product_type: null,
            group_id: null,
            has_commission: null,
            has_ds_commission: null,
            hwcustomprice: null,
            ismainsupplier: null,
            issupsupplier: null,
            loadingport_id: null,
            logo: null,
            other_locations: [],
            otsname: null,
            paymentstatement_id: null,
            pefc: null,
            pefc_certificate: null,
            pefc_claim: null,
            pefc_expiry_date: null,
            pefc_material_category: null,
            pefc_product_type: null,
            productgroup_id: null,
            productgroup2_id: null,
            productgroup3_id: null,
            remarks: null,
            species_id: null,
            subsupplier_id: null,
            suppliers_represented: [],
            suppliertype_id: 1,
            supplierstatus_id: 1,
            swcustomprice: null,
            title: null,
            wpr_paymentstatement_id: null,
        },
        Product: {
            certifications: [],
            comments: null,
            grades: [],
            is_export: null,
            is_import: null,
            mcs: [],
            origins: [],
            products: [],
            species: [],
            product_lengths: null,
            product_thicknesses: null,
            product_widths: null
        }
    }
}

const namespaced = {
    namespaced: true
}

const state = {
    allActiveSuspendedSuppliers: [],
    allSuppliers: [],
    filteredActiveSuspendedSuppliers: [],
    filteredSuppliers: [],
    supplierAgents: [],
    supplierCountries: [],
    supplierSpecies: [],
    supplierTypes: [],
    current: {
        AgentCommissions: [],
        Company: {
            id: null,
            address1: null,
            address2: null,
            address3: null,
            brnno: null,
            city: null,
            country_id: null,
            contacts: [],
            email: null,
            fax: null,
            GPCustomerNumber: null,
            gstno: null,
            latitude: null,
            longitude: null,
            name: null,
            override_ots_country: null,
            postcode: null,
            sstno: null,
            state: null,
            taxno_type: null,
            tel: null,
            uscc: null,
            website: null
        },
        Supplier: {
            id: null,
            account_name: null,
            auto_notifications: 1,
            code: null,
            company_id: null,
            customcommfunc: null,
            documents: [],
            fsc: null,
            fsc_certificate: null,
            fsc_expiry_date: null,
            fsc_material_category: null,
            fsc_product_type: null,
            has_commission: null,
            has_ds_commission: null,
            hwcustomprice: null,
            ismainsupplier: null,
            issupsupplier: null,
            loadingport_id: null,
            logo: null,
            other_locations: [],
            otsname: null,
            pefc: null,
            pefc_certificate: null,
            pefc_claim: null,
            pefc_expiry_date: null,
            pefc_material_category: null,
            pefc_product_type: null,
            paymentstatement_id: null,
            productgroup_id: null,
            productgroup2_id: null,
            productgroup3_id: null,
            remarks: null,
            species_id: null,
            subsupplier_id: null,
            suppliers_represented: [],
            suppliertype_id: 1,
            supplierstatus_id: 1,
            swcustomprice: null,
            title: null,
            wpr_paymentstatement_id: null
        },
        Product: {
            certifications: [],
            comments: null,
            grades: [],
            is_export: null,
            is_import: null,
            mcs: [],
            origins: [],
            products: [],
            species: [],
            product_lengths: null,
            product_thicknesses: null,
            product_widths: null
        }
    },
    update: {
        AgentCommissions: [],
        Company: {},
        Supplier: {
            documents: []
        },
        Product: {
            certifications: [],
            comments: null,
            grades: [],
            is_export: null,
            is_import: null,
            mcs: [],
            origins: [],
            products: [],
            species: [],
            product_lengths: null,
            product_thicknesses: null,
            product_widths: null
        }
    },
    newSupplierSaved: false,
    newSupplierUnsaved: false,
    options: {
        certifications: [],
        compositions: [],
        densities: [],
        designs: [],
        dryingTypes: [],
        finishes: [],
        glues: [],
        grades: [],
        lengths: [],
        mcs: [],
        origins: [],
        products: [],
        species: [],
        surfaces: [],
        thicknesses: [],
        widths: []
    },
    updatedSupplierUnsaved: false,
    updatedSupplierSaved: false
}

const getters = {
    getField,
    allActiveSuspendedSuppliers: state => {
        return state.allActiveSuspendedSuppliers;
    },
    allAgents: state => {
        let agents = state.allSuppliers.filter(supplier => {
            return supplier.Supplier.suppliertype_id == 3 || supplier.Supplier.suppliertype_id == 4
        })
        agents.sort(function(a,b){ return ((a.Supplier.otsname < b.Supplier.otsname) ? -1 : ((a.Supplier.otsname > b.Supplier.otsname) ? 1 : 0))})
        return agents
    },
    allPureSuppliers: state => {
        let pureSuppliers = state.allSuppliers.filter(supplier => supplier.Supplier.issupsupplier !== 1)
        pureSuppliers.sort(function(a,b){ return ((a.Supplier.otsname < b.Supplier.otsname) ? -1 : ((a.Supplier.otsname > b.Supplier.otsname) ? 1 : 0))})
        return pureSuppliers
    },
    allSuppliers: state => {
        return state.allSuppliers;
    },
    findSupplierById: state => {
        return id => state.allSuppliers.find (supplier => {
            return supplier.Supplier.id === id
        })
    },
    findAgentById: state => {
        return id => state.supplierAgents.find (supplier => {
            return supplier.Agent.id === id
        })
    },
    supplierAgents: state => { return state.supplierAgents },
    supplierCountries: state => { return state.supplierCountries },
    supplierSpecies: state => { return state.supplierSpecies },
    supplierTypes: state => { return state.supplierTypes },
}

const actions = {
    activateSupplierById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .put("/suppliers/" + val + '/activate')
                .then(response => {
                    if(response.data.status === 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    checkSupplierBeforeDestroy({ state }, val) {
        return new Promise((resolve, reject) => {
            api
                .get("/suppliers/" + val + "/check-records")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    createSupplier ({state, rootState}) {
        let company = _.cloneDeep(state.current.Company)
        const supplier = _.cloneDeep(state.current.Supplier)
        const product = _.cloneDeep(state.current.Product)
        delete company.contacts

        return new Promise((resolve, reject)=>{
            api
                .post("/suppliers",{
                    company: company,
                    supplier: supplier,
                    contacts: rootState.appic.contact.current.Contacts,
                    product: product
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    destroySupplierById({ state }, val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/suppliers/" + val + "/destroy")
                .then(response => {
                    if(response.data.status === 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    filterAllActiveSuspendedSuppliers ({commit,state},payload) {
        return new Promise((resolve, reject) => {
            let filterOptions = payload.filterOptions
            let filters = {}
            if (filterOptions.type?.Suppliertype?.id) {
                Object.assign(filters, {'Supplier': {'suppliertype_id': [filterOptions.type.Suppliertype.id]}})
            }
            if (filterOptions.agent?.Agent?.id) {
                Object.assign(filters, {'Supplier': {'subsupplier_id': [filterOptions.agent.Agent.id]}})
            }
            if (filterOptions.country?.Country?.id) {
                Object.assign(filters, {'Company': {'country_id': [filterOptions.country.Country.id]}})
            }
            if (filterOptions.species?.Species?.id) {
                Object.assign(filters, {'Supplier': {'species_id': [filterOptions.species.Species.id]}})
            }
            let suppliers = state.allActiveSuspendedSuppliers.filter(d => {
                return Object.keys(filters).every(f => {
                    return Object.keys(filters[f]).every(k => {
                        return filters[f][k].length < 1 || filters[f][k].includes(d[f][k])
                    })
                })
            })
            commit('setFilteredActiveSuspendedSuppliers', suppliers);
            resolve('done')
        })
    },
    filterAllSuppliers ({commit,state},payload) {
        return new Promise((resolve, reject) => {
            let filterOptions = payload.filterOptions
            let filters = {}
            if (filterOptions.type?.Suppliertype?.id) {
                Object.assign(filters, {'Supplier': {'suppliertype_id': [filterOptions.type.Suppliertype.id]}})
            }
            if (filterOptions.agent?.Agent?.id) {
                Object.assign(filters, {'Supplier': {'subsupplier_id': [filterOptions.agent.Agent.id]}})
            }
            if (filterOptions.country?.Country?.id) {
                Object.assign(filters, {'Company': {'country_id': [filterOptions.country.Country.id]}})
            }
            if (filterOptions.species?.Species?.id) {
                Object.assign(filters, {'Supplier': {'species_id': [filterOptions.species.Species.id]}})
            }
            let suppliers = state.allSuppliers.filter(d => {
                return Object.keys(filters).every(f => {
                    return Object.keys(filters[f]).every(k => {
                        return filters[f][k].length < 1 || filters[f][k].includes(d[f][k])
                    })
                })
            })
            commit('setFilteredSuppliers', suppliers);
            resolve('done')
        })
    },
    getAllActiveSuspendedSuppliers({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/suppliers/all")
                .then(response => {
                    commit('setAllActiveSuspendedSuppliers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllSuppliers({commit}){
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers")
              .then(response => {
                  commit('setAllSuppliers',response.data.data);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    getAllSupplierAgents ({commit}) {
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers/agents",{
              })
              .then(response => {
                  commit('setSupplierAgents',response.data.data);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    getAllSupplierCountries ({commit}) {
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers/countries",{
              })
              .then(response => {
                  commit('setSupplierCountries',response.data.data);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    getAllSupplierSpecies ({commit}) {
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers/species",{
              })
              .then(response => {
                  commit('setSupplierSpecies',response.data.data);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    getAllSupplierTypes ({commit}) {
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers/types",{
              })
              .then(response => {
                  commit('setSupplierTypes',response.data.data);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    getSupplierToUpdateById ({commit},supplier_id) {
        return new Promise((resolve, reject) => {
            api
              .get("/suppliers/" + supplier_id,{
              })
              .then(response => {
                  commit('setSupplierToBeUpdated',response.data.data[0]);
                  resolve('done')
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    resetAllSuppliers ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCommissions ({commit}, prefix) {
        commit('resetCommissionState', prefix)
    },
    resetCurrentSupplier ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    searchAllActiveSuspendedSuppliers ({commit, state},payload) {
        let suppliers = state.allActiveSuspendedSuppliers.filter(d => {
            if(payload.search.field.split('.').reduce((o,i)=>o[i], d)) {
                let fieldValue = payload.search.field.split('.').reduce((o, i) => o[i], d).toLowerCase()
                let searchTerm = payload.search.value.toLowerCase()
                return fieldValue.includes(searchTerm)
            } else {
                return false
            }
        })
        commit('setFilteredActiveSuspendedSuppliers',suppliers);
    },
    searchAllSuppliers ({commit, state},payload) {
        let suppliers = state.allSuppliers.filter(d => {
            if(payload.search.field.split('.').reduce((o,i)=>o[i], d)) {
                let fieldValue = payload.search.field.split('.').reduce((o, i) => o[i], d).toLowerCase()
                let searchTerm = payload.search.value.toLowerCase()
                return fieldValue.includes(searchTerm)
            } else {
                return false
            }
        })
        commit('setFilteredSuppliers',suppliers);
    },
    setAgentCommissions ({commit}, payload) {
        commit('setCommissions', payload);
    },
    suspendSupplierById({commit},val){
        return new Promise((resolve, reject) => {
            api
              .put("/suppliers/" + val + "/suspend")
              .then(response => {
                  if(response.data.status === 'success') {
                      resolve('done')
                  } else {
                      resolve('error')
                  }
              })
              .catch(error => {
                  reject(error);
              });
        })
    },
    updateSupplier ({state, rootState}) {
        let company = _.cloneDeep(state.update.Company)
        let supplier = _.cloneDeep(state.update.Supplier)
        const documents = _.cloneDeep(state.update.Supplier.documents)
        const product = _.cloneDeep(state.update.Product)
        const commissions = _.cloneDeep(state.update.AgentCommissions)
        //remove unneeded
        delete company.contacts
        delete supplier.documents

        return new Promise((resolve, reject) => {
            api
                .put("/suppliers/" + state.update.Supplier.id ,
                    {
                        company: company,
                        supplier: supplier,
                        contacts: rootState.appic.contact.update.Contacts,
                        documents: documents,
                        product: product,
                        commissions: commissions
                    })
                .then(response => {
                    this.updatedSupplierSaved = true
                    this.updatedSupplierUnsaved = false
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

const mutations = {
    resetCommissionState(state, prefix) {
        state[prefix].AgentCommissions = getDefaultState().AgentCommissions
    },
    resetCurrentState (state) {
        console.log('Resetting this supplier...')
        state.current.Company = getDefaultState().Company
        state.current.Supplier = getDefaultState().Supplier
        state.newSupplierUnsaved = false
        state.newSupplierSaved = false
    },
    resetState (state){
        state.allSuppliers = getDefaultState().allSuppliers
        state.allActiveSuspendedSuppliers = getDefaultState().allActiveSuspendedSuppliers
        state.current.Company = getDefaultState().Company
        state.current.Supplier = getDefaultState().Supplier
    },
    setCommissions(state, payload) {
        state[payload.prefix].AgentCommissions = payload.data
        let i = 0;
        state[payload.prefix].AgentCommissions.forEach((item) => {
            item.index = i
            i++
        })
    },
    setAllActiveSuspendedSuppliers(state,data){
        state.allActiveSuspendedSuppliers = data;
        state.filteredActiveSuspendedSuppliers = data;
    },
    setAllSuppliers(state,data){
        state.allSuppliers = data;
        state.filteredSuppliers = data;
    },
    setFilteredSuppliers (state,data) {
        state.filteredSuppliers = data;
    },
    setFilteredActiveSuspendedSuppliers (state,data) {
        state.filteredActiveSuspendedSuppliers = data;
    },
    setSupplierAgents (state,data) {
        state.supplierAgents = data;
    },
    setSupplierCountries (state,data) {
        state.supplierCountries = data;
    },
    setSupplierSpecies (state,data) {
        state.supplierSpecies = data;
    },
    setSupplierToBeUpdated (state,data) {
        state.update.Company = data.Company
        state.update.Supplier = data.Supplier
        state.update.Product = data.Product
    },
    setSupplierTypes (state,data) {
        state.supplierTypes = data;
    },
    updateAgentCommissionItems (state, payload) {
        const idx = payload['index']
        const keys = payload['field'].split('.')
        const prefix = payload['prefix']
        state[prefix].AgentCommissions[idx][keys[0]][keys[1]] = payload['value'];
    },
    updateField
}

export const supplier = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}