import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'
import {log} from "../../../helpers/helpers";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		filteredContracts: [],
		filteredNcrs: [],
		Contract: {
			id: null,
			title: null
		},
		Ncr: {
			containercount: null,
			containersize_id: 1,
			contract_id: null,
			currency_id: 2,
			destination_id: null,
			documents: [],
			customer_id: null,
			etadate: null,
			etddate: null,
			eutr_compliance_required: null,
			fsc: null,
			grade_remarks: null,
			id: null,
			incoterm_id: 2,
			incotermport: 0,
			internal_remarks: null,
			is_export: null,
			is_others: null,
			loadingport_id: null,
			merge_attachments_to_pdf: 1,
			ms_id: 1,
			ncrdate: null,
			office_id: 1,
			paymentstatement_id: null,
			popaymentstatement_id: null,
			po_remarks: null,
			po_remarks_same_contract: 1,
			productgroup_id: null,
			productorigin_id: null,
			remarks: null,
			salescontact_id: null,
			salestype_id: 1,
			shippingport_id: null,
			shippingweek: null,
			size_remarks: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			Salescontact: {
				name: null
			},
			SupplierAgent: {
				name: null,
				code: null,
			}
		}
	}
}

const state = {
	allContractRequests: [],
	filteredContracts: [],
	filteredNcrs: [],
	current: {
		Contract: {
			id: null,
			title: null
		},
		Ncr: {
			containercount: null,
			containersize_id: 1,
			contract_id: null,
			currency_id: 2,
			customer_id: 0,
			destination_id: null,
			documents: [],
			etadate: null,
			etddate: null,
			eutr_compliance_required: null,
			fsc: null,
			grade_remarks: null,
			id: null,
			incoterm_id: 2,
			incotermport: null,
			internal_remarks: null,
			is_export: null,
			is_others: null,
			loadingport_id: null,
			merge_attachments_to_pdf: 1,
			ms_id: 1,
			ncrdate: null,
			office_id: 1,
			paymentstatement_id: null,
			popaymentstatement_id: null,
			po_remarks: null,
			po_remarks_same_contract: 1,
			productgroup_id: null,
			productorigin_id: null,
			remarks: null,
			salescontact_id: null,
			salestype_id: 1,
			size_remarks: null,
			shippingport_id: null,
			shippingweek: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			Salescontact: {
				id: null,
				name: null
			},
			SupplierAgent: {
				name: null,
				code: null,
			}
		},
	},
	update: {
		Contract: {
			id: null,
			title: null
		},
		Ncr: {
			id: null,
			contract_id: null,
			documents: [],
			Salescontact: {
				id: null,
				name: null
			},
			SupplierAgent: {
				name: null,
				code: null,
			}
		}
	},
	newContractRequestSaved: false,
	newContractRequestUnsaved: false,
	totalContractRequests: 0,
	updatedContractRequestSaved: false,
	updatedContractRequestUnsaved: false
}

const getters = {
	getField,
	filteredContracts: state => {
		return state.filteredContracts
	},
	filteredNcrs: state => {
		return state.filteredNcrs
	},
}

const mutations = {
	resetCurrentState(state) {
		console.log('Resetting this Ncr...')
		state.current.Ncr = getDefaultState().Ncr
		state.current.Contract = getDefaultState().Contract
		state.newContractRequestSaved = false
		state.newContractRequestUnsaved = false
	},
	resetState (state){
		console.log('Resetting Contract Requests...')
		state.allContractRequests = []
		state.totalContractRequests = 0
	},
	resetUpdateState(state) {
		console.log('Resetting this Ncr...')
		state.update.Ncr = getDefaultState().Ncr
		state.update.Contract = getDefaultState().Contract
		state.updatedContractRequestSaved = false
		state.updatedContractRequestUnsaved = false
	},
	setFilteredContracts(state, data) {
		state.filteredContracts = data;
	},
	setFilteredNcrs(state, data) {
		state.filteredNcrs = data;
	},
	setContract(state, payload) {
		console.log('Setting the related Contract')
		state[payload.prefix].Contract = payload.contract
		state[payload.prefix].Contract.contract_id = payload.contract.id
	},
	setFilteredContractsEmpty(state) {
		state.filteredContracts = getDefaultState().filteredContracts;
	},
	setFilteredNcrsEmpty(state) {
		state.filteredNcrs = getDefaultState().filteredNcrs;
	},
	setNcrFromContract(state, payload) {
		let prefix = payload.prefix
		let contract = payload.contract[0]['Contract']
		// let po = contract.Purchaseorder

		state[prefix].Ncr.containercount = contract.containercount
		state[prefix].Ncr.containersize_id = contract.containersize_id
		state[prefix].Ncr.currency_id = contract.currency_id
		state[prefix].Ncr.customer_id = contract.customer_id
		state[prefix].Ncr.fsc = contract.fsc

		state[prefix].Ncr.incoterm_id = contract.incoterm_id
		if(parseInt(contract.incoterm_id) === 1) state[prefix].Ncr.incotermport = 1
		if(parseInt(contract.incoterm_id) === 2) state[prefix].Ncr.incotermport = 0

		state[prefix].Ncr.is_export = contract.is_export
		state[prefix].Ncr.office_id = parseInt(contract.office_id) === 3 ? 1 : contract.office_id
		state[prefix].Ncr.paymentstatement_id = contract.paymentstatement_id
		state[prefix].Ncr.productgroup_id = contract.productgroup_id
		state[prefix].Ncr.productorigin_id = contract.productorigin_id
		state[prefix].Ncr.salescontact_id = contract.salescontact_id
		state[prefix].Ncr.remarks = contract.note
		state[prefix].Ncr.salestype_id = contract.salestype_id
		state[prefix].Ncr.shippingport_id = contract.shippingport_id
		state[prefix].Ncr.supplier_id = contract.supplier_id

		if(contract.ms_id != null){
			state[prefix].Ncr.ms_id = contract.ms_id
		}

		if(parseInt(contract.is_export) === 1){
			// state[prefix].Ncr.incoterm_id = 1
			// state[prefix].Ncr.incotermport = 1
			// state[prefix].Ncr.loadingport_id = contract.loadingport_id
		}

		//added missing 2022-11-18
		state[prefix].Ncr.destination_id = contract.destinatione_id
		state[prefix].Ncr.eutr_compliance_required = contract.eutr_compliance_required
		state[prefix].Ncr.is_others = contract.is_others

		//added 2025-03-03
		state[prefix].Ncr.productorigin_id = contract.productorigin_id
		state[prefix].Ncr.loadingport_id = contract.loadingport_id
	},
	setNcrFromNcr(state, payload) {
		let prefix = payload.prefix
		let ncr = payload.ncr[0]['Ncr']
		state[prefix].Ncr.containercount = ncr.containercount
		state[prefix].Ncr.containersize_id = ncr.containersize_id
		state[prefix].Ncr.currency_id = ncr.currency_id
		state[prefix].Ncr.customer_id = ncr.customer_id
		state[prefix].Ncr.destination_id = ncr.destination_id
		state[prefix].Ncr.fsc = ncr.fsc
		state[prefix].Ncr.incoterm_id = ncr.incoterm_id
		state[prefix].Ncr.incotermport = ncr.incotermport
		state[prefix].Ncr.is_export = ncr.is_export
		state[prefix].Ncr.loadingport_id = ncr.loadingport_id
		state[prefix].Ncr.office_id = parseInt(ncr.office_id) === 3 ? 1 : ncr.office_id
		state[prefix].Ncr.paymentstatement_id = ncr.paymentstatement_id
		state[prefix].Ncr.productgroup_id = ncr.productgroup_id
		state[prefix].Ncr.productorigin_id = ncr.productorigin_id
		state[prefix].Ncr.salescontact_id = ncr.salescontact_id
		state[prefix].Ncr.remarks = ncr.remarks
		state[prefix].Ncr.salestype_id = ncr.salestype_id
		state[prefix].Ncr.shippingport_id = ncr.shippingport_id
		state[prefix].Ncr.supplier_id = ncr.supplier_id
		state[prefix].Ncr.ms_id = ncr.ms_id
		if(parseInt(ncr.is_export) === 1){
			state[prefix].Ncr.incoterm_id = 1
			state[prefix].Ncr.incotermport = 1
			state[prefix].Ncr.loadingport_id = ncr.loadingport_id
		}
		state[prefix].Ncr.eutr_compliance_required = ncr.eutr_compliance_required
		state[prefix].Ncr.is_others = ncr.is_others
	},
	setNcrs(state, data) {
		state.allContractRequests = data;
	},
	setNcrToBeUpdated (state, data) {
		state.update.Ncr = data.Ncr
		state.update.Ncr.SupplierAgent = data.Ncr.SupplierAgent
		state.update.Contract = data.Contract
	},
	setTotalNcrs (state,val) {
		state.totalContractRequests = val
	},
	updateField,
	updateNcrStatus (state, payload) {
		state[payload.prefix].Ncr.status_id = payload.status
	}
}

const actions = {
	approveNcrById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/ncrs/" + val + "/approve")
				.then(response => {
					if(response.data.status === 'success') {
						let payload = {
							status: 3,
							prefix: 'update'
						}
						commit('updateNcrStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	cancelNcrById({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/ncrs/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	convertNcrToContractById({commit},payload) {
		return new Promise((resolve, reject) => {
			api
				.put("/ncrs/" + payload.ncr_id + '/convert',{
					new_contract_number: payload.contract_number
				})
				.then(response => {
					if(response.data.status == 'success') {
						if(payload.setContract) {
							let payload2 = {
								prefix: payload.prefix,
								contract: {
									id: response.data.contract_id,
									title: response.data.contract_title
								}
							}
							commit('setContract', payload2)
						}
					}
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	copyContractToNcr({commit, rootState}, payload) {
		return new Promise ((resolve, reject) => {
			try {
				commit('setNcrFromContract', payload)
				setTimeout(function () {
					resolve('done')
				}, 1000)
			} catch(e) {
				reject(e)
			}
		})
	},
	copyNcrToNcr({commit, rootState}, payload) {
		return new Promise ((resolve, reject) => {
			try {
				commit('setNcrFromNcr', payload)
				setTimeout(function () {
					resolve('done')
				}, 1000)
			} catch(e) {
				log(e)
				reject(e)
			}
		})
	},
	createNcr ({state, rootState}){
		let ncr = _.cloneDeep(state.current.Ncr)
		const documents = _.cloneDeep(state.current.Ncr.documents)

		delete ncr.Salescontact
		delete ncr.SupplierAgent
		delete ncr.documents

		let ncrItems = _.cloneDeep(rootState.appic.ncritem.current.NcrItems)
		ncrItems.forEach((item,idx) => {
			delete ncrItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.post('/ncrs',
					{
						ncr: ncr,
						ncritems: ncrItems,
						documents: documents
					}
				)
				.then((response) => {
					if(response.data.status == 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
					// resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
			// .finally(()=>{
			// 	resolve('error')
			// })
		})
	},
	getAllNcrs({commit},payload) {
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/ncrs", {
						params: {
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'Ncr.ncrdate',
									direction: 'DESC'
								}
							]
						}
					})
					.then(response => {
						commit('setNcrs', response.data.data)
						commit('setTotalNcrs', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getNcrToUpdateById({commit}, ncr_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/ncrs/' + ncr_id)
				.then(response => {
					commit('setNcrToBeUpdated', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	rejectNcrById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/ncrs/" + val + "/reject")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 4,
							prefix: 'update'
						}
						commit('updateNcrStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetAllActiveNcrs ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentNcr({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
			reject('error')
		})
	},
	resetFilteredContracts({commit}) {
		commit('setFilteredContractsEmpty');
	},
	resetFilteredNcrs({commit}) {
		return new Promise ((resolve, reject) => {
			commit('setFilteredNcrsEmpty');
			resolve('done')
			reject('error')
		})
	},
	resetUpdateNcr({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetUpdateState')
			resolve('done')
			reject('error')
		})
	},
	searchAllNcrs ({commit}, payload){
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/ncrs",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// skip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
						// order: [
						// 	{
						// 		field: 'ncrs.ncrdate',
						// 		direction: 'DESC'
						// 	},
						// 	{
						// 		field: 'ncrs.id',
						// 		direction: 'DESC'
						// 	},
						// ]
					}
				})
				.then(response => {
					commit('setNcrs',response.data.data);
					commit('setTotalNcrs',response.data.totalRows);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	setSalesContactContracts({commit}, contracts) {
		commit('setFilteredContracts', contracts)
	},
	setSalesContactNcrs({commit}, ncrs) {
		commit('setFilteredNcrs',ncrs)
	},
	submitNcrById({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/ncrs/" + val + "/submit")
				.then(response => {
					if(response.data.status === 'success') {
						let payload = {
							status: 2,
							prefix: 'update'
						}
						commit('updateNcrStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	updateNcr({state, rootState}) {
		let ncr = _.cloneDeep(state.update.Ncr)
		let documents = _.cloneDeep(state.update.Ncr.documents)

		//remove unneeded properties
		delete ncr.documents
		delete ncr.Customer
		delete ncr.Destination
		delete ncr.Incoterm
		delete ncr.Origin
		delete ncr.Paymentstatement
		delete ncr.PoPaymentstatement
		delete ncr.Salescontact
		delete ncr.ShippingPort
		delete ncr.Status
		delete ncr.Supplier
		delete ncr.SupplierAgent

		let ncrItems = _.cloneDeep(rootState.appic.ncritem.update.NcrItems)
		ncrItems.forEach((item,idx) => {
			delete ncrItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.put('/ncrs/' + state.update.Ncr.id,
					{
						ncr: ncr,
						ncritems: ncrItems,
						documents: documents
					}
				)
				.then((response)=>{
					this.updatedContractRequestSaved = true
					this.updatedContractRequestUnsaved = false
					resolve('done')
					// resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
			// .finally(()=>{
			// 	resolve('done')
			// })
		})
	},
}

export const ncr = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}