import {api} from "Api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "Helpers/helpers";

const namespaced = {
    namespaced: true
}

const getOtherChargesRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Grade: {
            id: 39,
            title: 'Other Charges'
        },
        Lmeasurement: {
            id: null,
            title: ''
        },
        Smeasurement: {
            id: null,
            title: ''
        },
        Soldmeasurement: {
            id: null,
            title: ''
        },
        Sosalesdescription: {
            id: null,
            title: ''
        },
        Spec: {
            id: null,
            title: null,
        },
        Species: {
            id: null,
            title: ''
        },
        Stock: {
            adjusted_grading_cost: 0,
            bundleno: null,
            costamount: 0,
            costamount_converted: 0,
            costprice: 0,
            forwardingcharge: 0,
            fsc: null,
            fsc_description: null,
            grade_id: 39,
            id: null,
            invoiceamount: 0,
            invoiceamount_converted: 0,
            length: null,
            piece: 0,
            productgroup_id: null,
            sellingprice: 0,
            sellingprice_converted: 0,
            sheets: 0,
            size: 'Other Charges',
            spec_id: null,
            species_id: null,
            thick: null,
            totalcost: 0,
            volume: 1,
            warehousecost:0,
            width: null,
            wh_pallet_no: null
        },
        Stockorder: {
            id: null,
            currency_id: 1,
            currencyrate: 1,
            title: ''
        },
        Wmeasurement: {
            id: null,
            title: ''
        },
        Options: {
            grades: [],
            species: [],
            specs: []
        },
        type: 'othercharge'
    }
}

const getDefaultState = () => {
    return {
        Stock: {
            id: null,
            adjusted_grading_cost: 0,
            bundleno: null,
            container_id: null,
            costprice: 0,
            forwardingcharge: 0,
            fsc: null,
            fsc_description: null,
            grade_id: null,
            grossweight: 0,
            length: null,
            lmeasurement_id: null,
            localsale_id: null,
            localsalesorder_id: null,
            margin: 0,
            mc_id: null,
            measurement_id: null,
            measurementrate: 1.0,
            netweight: 0,
            parent_id: null,
            piece: 0,
            productgroup_id: null,
            salesdescription: null,
            salesdescription_id: null,
            salesitem_id: null,
            sellingprice: 0,
            sheets: 0,
            size: null,
            size_id: null,
            smeasurement_id: null,
            soldmeasurement_id: null,
            sosalesdescription_id: null,
            species_id: null,
            speciesgroup_id: null,
            stock_country_id: null,
            stock_id: null,
            stockdescription: null,
            stockorder_id: null,
            stockstate_id: null,
            thick: null,
            tonnage: 0,
            unitvolume: 0.0,
            volume: 0,
            warehousecost: 0,
            width: null,
            wh_pallet_no: null,
            wmeasurement_id: null,
            SalesDescription: {
                id: null,
                title: null,
                localsalesorder_id: null
            }
        },
        Options: {
            grades: [],
            species: [],
            specs: []
        }
    }
}

const state = {
    current: {
        LocalSalesInvoiceItems: [
            {
                Stock: {
                    StockOrder: {
                        currency_id: 2
                    }
                },
                Productgroup: {
                    id: null,
                    title: null
                }
            }
        ],
        Salesitems: [],
        commissionByUnit: false,
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalCostConverted: 0,
        itemsTotalMargin: 0,
        itemsTotalPieces: 0,
        itemsTotalQty: [],
        itemsTotalSheets: 0,
        itemsTotalTax: 0,
        itemsTotalTaxConverted: 0,
        itemsGrandTotal: 0,
        itemsGrandTotalConverted: 0,
        gstpercent: 0,
        specificationNotes: ''
    },
    update: {
        LocalSalesInvoiceItems: [],
        itemsCurrencyRate: 1,
        gstpercent: 0,
        itemsGrandTotal: 0,
        itemsTotalAmount: 0,
        itemsTotalQty: [],
        itemsTotalSheets: 0,
        itemsTotalTax: 0,
        itemsTotalPieces: 0
    }
}

const getters = {
    getField,
    itemsCurrencyRate: state => {return state.current.itemsCurrencyRate },
    itemsTotalAmount: state => { return state.current.itemsTotalAmount },
    itemsTotalAmountConverted: state => { return state.current.itemsTotalAmountConverted },
    itemsTotalCommission: state => { return state.current.itemsTotalCommission },
    itemsTotalCost: state => { return state.current.itemsTotalCost },
    itemsTotalCostConverted: state => { return state.current.itemsTotalCostConverted },
    itemsTotalMargin: state => { return state.current.itemsTotalMargin },
    itemsTotalMarginConverted: state => { return state.current.itemsTotalMarginConverted },
    itemsTotalQty: state => { return state.current.itemsTotalQty },
    itemsTotalSheets: state => { return state.current.itemsTotalSheets },
    itemsTotalTax: state => { return state.current.itemsTotalTax },
    itemsTotalTaxConverted: state => { return state.current.itemsTotalTaxConverted },
    itemsGrandTotal: state => { return state.current.itemsGrandTotal },
    itemsGrandTotalConverted: state => { return state.current.itemsGrandTotalConverted },
    commissionByUnit: state => {return state.current.commissionByUnit},
    specificationNotes: state => {return state.current.specificationNotes}
}

const mutations = {
    addOtherChargesItemRow (state, prefix) {
        let i = 0;
        let newItem = getOtherChargesRow()
        newItem.Stock.stock_id = null
        newItem['Options'] = {
            grades: [],
            species: [],
            specs: []
        }
        state[prefix].LocalSalesInvoiceItems.push(newItem)
        state[prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    deleteLocalSalesInvoiceItemRow (state, payload) {
        state[payload.prefix].LocalSalesInvoiceItems.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState () {
        console.log('Resetting invoice sales items...')
        state.current.LocalSalesInvoiceItems = []
    },
    setCurrentSalesItems (state, data) {
        state.current.Salesitems = data;
        let i = 0;
        state.current.Salesitems.forEach((item,index)=>{
            state.current.Salesitems[index]['index'] = i;
            state.current.Salesitems[index]['lineNumber'] = i + 1;
            state.current.Salesitems[index]['Options'] = {
                grades: [],
                species: [],
                specs: []
            };
            i++
        })
    },
    setCurrentLocalSalesInvoiceItemsFromSalesOrder ( state, data ) {
        state.current.LocalSalesInvoiceItems = data
        let i = 0;
        state.current.LocalSalesInvoiceItems.forEach((item) => {
            item.salesOrderItemId = item.Stock.id;
            item.Stock.salesdescription_id = item.Stock.sosalesdescription_id;
            item.Stock.id = item.Stock.stock_id;
            item.index = i;
            item.lineNumber = i + 1;
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            };
            i++;
        })
    },
    setCurrentLocalSalesInvoiceItems (state, data) {
        state.current.LocalSalesInvoiceItems = data
        let i = 0
        state.current.LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            }
            i++
        })
    },
    setLocalSalesInvoiceItems ( state, payload ) {
        state[payload.prefix].LocalSalesInvoiceItems = payload.data
        let i = 0
        state[payload.prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            }
            i++
        })
    },
    updateField,
    updateLocalSalesInvoiceItems (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        state[prefix].LocalSalesInvoiceItems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['costprice','forwardingcharge','invoiceamount','sellingprice','warehousecost']
        if(keys[0] === 'Stock' && numberFields.indexOf(keys[1]) !== -1){
            if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] === 'default') {
                //update amount
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['amount'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice'])

                //update cost
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['totalcost'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['costprice']) + parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['forwardingcharge']) + parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['warehousecost'])

                //update costamount
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['costamount'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['totalcost'])
            }
            if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] === 'othercharge'){
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['amount'] = 1 * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice'])
            }
            //update converted fields
            // if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'default') {
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice']) * state[prefix].itemsCurrencyRate
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * (parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice']) * state[prefix].itemsCurrencyRate)
            // } else if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'othercharge') {
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invprice_converted'] = 0;
            //     state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount']) * state[prefix].itemsCurrencyRate
            // }
        }
    },
    updateLocalSalesInvoiceItemRow (state, payload) {
        const numberFields = ['volume','sellingprice']
        const prefix = payload['prefix']
        const itemIndex = payload['index']
        const stock = payload['stock']
        const stockProperties = Object.keys(stock)

        const spec = payload['spec']
        const species = payload['species']
        const mc = payload['mc']
        const grade = payload['grade']
        const certification = payload['certification']
        const productGroup = payload['productGroup']

        stockProperties.forEach((stockProperty) => {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock'][stockProperty] = stock[stockProperty]
            if(numberFields.indexOf(stockProperty) !== -1){
                state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['amount'] = parseFloat(state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['sellingprice'])
            }
        })
        if(spec.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Spec'] = spec
        }
        if(species.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Species'] = species
        }
        if(mc.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Mc'] = mc
        }
        if(grade.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Grade'] = grade
        }
        if(certification.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Certification'] = certification
        }
        if(productGroup.id != null) {
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Productgroup'] = productGroup
        }
    },
    updateLocalSalesInvoiceItemRows (state, payload) { //multiple rows. used for bulk price updates 2023-11-18
        const itemIndexes = payload['indexes']
        const prefix = payload['prefix']
        const values = payload['values']
        const stockProperties = Object.keys(values)

        itemIndexes.forEach((itemIndex) => {
            stockProperties.forEach((stockProperty) => {
                if(stockProperty === 'costing'){
                    const costingProperties = Object.keys(values[stockProperty])
                    costingProperties.forEach((costingProperty) => {
                        state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['costing'][costingProperty] = values['costing'][costingProperty]
                    })
                } else {
                    state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock'][stockProperty] = values[stockProperty]
                }
            })
            state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['amount'] = parseFloat(state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[itemIndex]['Stock']['sellingprice'])
        })
    },
    updateTotals ( state, prefix ) {
        let amount = 0,
            // amountConverted = 0,
            costAmount = 0,
            // costamountConverted = 0,
            tax = 0,
            // taxConverted = 0,
            grandTotal = 0,
            // grandTotalConverted = 0,
            quantities = [],
            pieces = 0,
            sheets = 0;

        state[prefix].LocalSalesInvoiceItems.forEach((item) => {
            if(item.type === 'credit') {
                amount -= isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0;
                // amountConverted -= isNumeric(item.Stock.invoiceamount_converted) ? parseFloat(item.Stock.invoiceamount_converted) : 0;
                costAmount += isNumeric(item.Stock.costamount) ? parseFloat(item.Stock.costamount) : 0;
            } else {
                amount += isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0;
                // amountConverted += isNumeric(item.Stock.invoiceamount_converted) ? parseFloat(item.Stock.invoiceamount_converted) : 0;
                costAmount += isNumeric(item.Stock.costamount) ? parseFloat(item.Stock.costamount) : 0;
            }
            if(item.type === 'default'){
                pieces += isNumeric(item.Stock.piece) ? parseInt(item.Stock.piece) : 0;
                sheets += isNumeric(item.Stock.sheets) ? parseInt(item.Stock.sheets) : 0;
            }
            if(item.type === 'default') {
                let idx = quantities.findIndex(t => t.Total.uofm === item.Soldmeasurement.title);
                if (idx !== -1) {
                    quantities[idx]['Total']['qty'] += isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                } else {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Soldmeasurement.title;
                    obj['Total']['qty'] = isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                    quantities.push(obj)
                }
            }
        })
        state[prefix].itemsTotalAmount = amount
        // state[prefix].itemsTotalAmountConverted = amountConverted
        tax = amount * state[prefix].gstpercent/100
        // taxConverted = amountConverted * state[prefix].gstpercent/100
        state[prefix].itemsTotalTax = tax
        // state[prefix].itemsTotalTaxConverted = taxConverted
        grandTotal = amount + tax
        // grandTotalConverted = amountConverted + taxConverted
        state[prefix].itemsGrandTotal = grandTotal
        // state[prefix].itemsGrandTotalConverted = grandTotalConverted
        state[prefix].itemsTotalQty = quantities
        state[prefix].itemsTotalCost = costAmount
        // state[prefix].itemsTotalCostConverted = costamountConverted
        state[prefix].itemsTotalMargin = amount - costAmount
        // state[prefix].itemsTotalMarginConverted = amountConverted - costamountConverted
        state[prefix].itemsTotalPieces = pieces

        //added 2025-01-23
        state[prefix].itemsTotalSheets = sheets
    }
}

const actions = {
    addOtherChargesItemRow ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            commit('addOtherChargesItemRow',prefix)
            resolve('done')
        })
    },
    deleteLocalSalesInvoiceItem ({commit}, payload) {
        return new Promise((resolve, reject) => {
            let localSalesInvoiceId = payload.localSalesInvoiceId
            let itemId = payload.itemId
            api
                .delete('/localsales/' + localSalesInvoiceId + '/salesitems/' + itemId)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // deleteLocalSalesItemRow ({commit},index){
    //     commit('deleteLocalSalesItemRow',index)
    // },
    getSalesItemsBySalesOrder ({commit}, payload) {
        commit('resetState')
        let localSalesOrderId = payload.localsalesorder_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/localsalesorders/" + localSalesOrderId + "/salesitems")
                .then(response => {
                    commit('setCurrentLocalSalesInvoiceItemsFromSalesOrder',response.data.data);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error);
                })
                .then(function(){
                    commit('updateTotals',prefix);
                });
        })
    },
    getSalesItemsByLocalSaleInvoice ({commit}, payload) {
        let localSaleId = payload.localSalesInvoiceId
        let prefix = payload.prefix
        commit('resetState')

        return new Promise((resolve, reject) => {
            api
                .get("/localsales/" + localSaleId + "/salesitems",{

                })
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setLocalSalesInvoiceItems', payload);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //TODO delete in the future?
    getSalesItemsByLocalSale ({commit}, localsale_id) {
        commit('resetState')
        return new Promise((resolve, reject) => {
            api
                .get("/localsales/" + localsale_id + "/salesitems",{

                })
                .then(response => {
                    commit('setCurrentLocalSalesInvoiceItems',response.data.data);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateTotals ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            commit('updateTotals',prefix)
            resolve('done')
        })
    }
}

export const localsalesitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}